import { useEffect, useRef } from 'react';
import QueueAnnouncer from '../QueueAnnouncer';

interface ContactCenterPaneProps {
  instanceAlias: string;
  contact: connect.Contact | null;
}

const isLocalhost = window.location.hostname === 'localhost';

function ContactCenterPane(props: ContactCenterPaneProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    connect.core.initCCP(ref.current, {
      ccpUrl: 'https://' + props.instanceAlias + '.my.connect.aws/ccp-v2',
      // loginUrl: isLocalhost
      //   ? undefined
      //   : undefined,
      loginUrl: 'https://bgis.awsapps.com/start/#/saml/default/BGIS%20OneCall%20-%20CCP/ins-5d721ee8f2b72ee0',
      loginPopup: true,               // optional, defaults to `true`
      loginPopupAutoClose: true,      // optional, defaults to `false`
      loginOptions: {                 // optional, if provided opens login in new window
        autoClose: true,              // optional, defaults to `false`
        height: 600,                  // optional, defaults to 578
        width: 400,                   // optional, defaults to 433
        top: 0,                       // optional, defaults to 0
        left: 0                       // optional, defaults to 0
      },
      softphone: {                    // optional, defaults below apply if not provided
        allowFramedSoftphone: true,   // optional, defaults to false
        disableRingtone: false,       // optional, defaults to false
        // ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
      },
      pageOptions: { //optional
        enableAudioDeviceSettings: true, //optional, defaults to 'false'
        enablePhoneTypeSettings: true //optional, defaults to 'true' 
      },

      // ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
      // ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
      // ccpLoadTimeout: 10000 //optional, defaults to 5000 (ms)
    });

    connect.contact((contact) => {
      contact.onDestroy(() => {
      });
    });
  }, [props.instanceAlias]);

  return <div className='flex flex-col flex-1 overflow-hidden gap-2'>
    <div className='group relative h-full'>
      <style>{`
      iframe {
        border-radius: 0.75rem;
      }
      `}</style>
      <div
        ref={ref}
        id='connect-ccp'
        className={`
        h-full bg-[#f2f2f2] rounded-xl
      `}
      ></div>
    </div>
    <QueueAnnouncer contact={props.contact} />
  </div>;
}

export default ContactCenterPane;