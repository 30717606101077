import useAgent from '../../../hooks/useAgent';

interface InboundControlPaneProps {
  contact: connect.Contact | null;
}

function ControlPanelDefault(props: InboundControlPaneProps) {
  const { primaryQueueArn } = useAgent();

  return <div className='w-full flex flex-col space-y-4 p-2'>
    <div className='flex flex-col h-1/2 space-y-2'>

    </div>
  </div>
}

export default ControlPanelDefault;