interface QueueAnnouncerProps {
  contact: connect.Contact | null;
}

function formatQueueName(queueName: string) {
  let formattedQueueName = queueName;

  switch (queueName) {
    case 'OneCall-ConnectivityIssues-English':
      formattedQueueName = 'Connectivity Issues English';
      break;
    case 'OneCall-ConnectivityIssues-French':
      formattedQueueName = 'Connectivity Issues French';
      break;
    case 'OneCall-DefectiveHardware-English':
      formattedQueueName = 'Defective Hardware English';
      break;
    case 'OneCall-DefectiveHardware-French':
      formattedQueueName = 'Defective Hardware French';
      break;
    case 'OneCall-General-English':
      formattedQueueName = 'General English';
      break;
    case 'OneCall-General-French':
      formattedQueueName = 'General French';
      break;
    case 'OneCall-PasswordReset-English':
      formattedQueueName = 'Password Reset English';
      break;
    case 'OneCall-PasswordReset-French':
      formattedQueueName = 'Password Reset French';
      break;
    case 'OneCall-SWAT-English':
      formattedQueueName = 'SWAT English';
      break;
    case 'OneCall-SWAT-French':
      formattedQueueName = 'SWAT French';
      break;
    case 'OneCall-Technicians-English':
      formattedQueueName = 'Technicians English';
      break;
    case 'OneCall-Technicians-French':
      formattedQueueName = 'Technicians French';
      break;
    case 'BasicQueue':
      formattedQueueName = 'Basic Queue';
      break;
    default:
      formattedQueueName = queueName;
      break;
  }

  return formattedQueueName;
}

function QueueAnnouncer(props: QueueAnnouncerProps) {
  const queueName = props.contact && props.contact.getQueue().name;

  return <div className='bg-[#f2f2f2] rounded-lg p-3'>
    <div className='flex flex-row items-center justify-between gap-2'>
      <span className='text-neutral-600 font-medium text-xs'>
        INCOMING QUEUE
      </span>
      {!queueName && <span
        className='text-neutral-400 font-medium text-sm'
      >N/A</span>}
      {queueName && <span
        className='text-neutral-700 font-medium text-sm'
      >{formatQueueName(queueName)}</span>}
    </div>
  </div>
}

export default QueueAnnouncer;
