import { memo, useContext } from 'react';
import {
  AgentContext,
  ConnectContext
} from '../../contexts';

function Header() {
  const connect = useContext(ConnectContext);
  const agent = useContext(AgentContext);

  return <header className='flex h-10 bg-neutral-800 py-2'>
    <div className='px-4 flex-1 flex items-center justify-between'>
      <div className='flex flex-row items-center space-x-2'>
        <div>
          <span className='font-semibold text-xs text-gray-300 select-none'>
            Agent CCP
          </span>
        </div>
        {/* <div className='bg-neutral-700 rounded-full px-3 py-1'>
          <span className='font-semibold text-xs text-gray-300 select-none'>
            BGIS
          </span>
        </div> */}
      </div>
      {/* <div>
        {agent && <>
          <div className='bg-neutral-700 rounded-full px-3 py-1'>
            <UserIcon className='h-3 w-3 inline-block mr-2 text-gray-200' />
            <span className='font-semibold text-xs text-gray-300 select-none'>
              {agent.getConfiguration().username} @ {connect.instanceAlias}
            </span>
          </div>
        </>}
      </div> */}
    </div>
  </header>
}

export default memo(Header);