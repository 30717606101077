import { useEffect, useState } from 'react';
import Header from './components/common/Header';
import {
  Layout,
  TwoPanelContainer
} from './components/containers';
import ContactCenterPane from './components/panes/ContactCenterPane';
import ControlPanelDefault from './components/panes/ControlPanel/ControlPanelDefault';
import {
  AgentContext,
  ConnectContext,
} from './contexts';

import "amazon-connect-streams";

let instanceAlias = 'bgis-onecall-ca';

declare global {
  interface Window {
    instanceAlias: string;
  }
}

window.instanceAlias = instanceAlias;

function App() {
  const [connectStatus, setConnectStatus] = useState({
    isInitialized: false,
    instanceAlias: window.instanceAlias
  });
  const [agent, setAgent] = useState<connect.Agent | null>(null);
  const [contact, setContact] = useState<connect.Contact | null>(null);

  useEffect(() => {
    connect.core.onInitialized(() => {
      setConnectStatus({
        isInitialized: true,
        instanceAlias: window.instanceAlias
      });
    });
  }, []);

  useEffect(() => {
    connect.agent((agent) => {
      setAgent(agent);

      agent.onRefresh((agent) => {
        setAgent(agent);
      });
    });

    return () => {
      setAgent(null);
    }
  }, []);

  useEffect(() => {
    connect.contact((contact) => {
      if (contact.getType() === connect.ContactType.VOICE) {
        setContact(contact);
        console.debug('AUI:App:Contact:Created', contact);

        contact.onDestroy(() => {
          setContact(null);
          console.debug('AUI:App:Contact:Destroyed');
        });
      }
    });

    return () => {
      setContact(null);
    }
  }, []);

  const attributes = contact?.getAttributes();
  const isCampaignContact = attributes?.['attributes_etcContactListId'] != null;

  console.log('AUI:App:Contact Attributes', attributes);

  // https://bgis.awsapps.com/start/#/saml/default/BGIS%20OneCall%20-%20CCP/ins-5d721ee8f2b72ee0
  return <Layout>
    <ConnectContext.Provider value={connectStatus}>
      <AgentContext.Provider value={agent}>
        {/* <ContactContext.Provider value={contact}> */}
        <Header />
        <TwoPanelContainer
          leftPanel={<ContactCenterPane
            instanceAlias={window.instanceAlias}
            contact={contact}
          />}
          rightPanel={<ControlPanelDefault contact={contact} />}
        />
        {/* </ContactContext.Provider> */}
      </AgentContext.Provider>
    </ConnectContext.Provider>
  </Layout>;
}

export default App;