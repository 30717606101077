import { useEffect, useState } from 'react';

function useAgent() {
  const [agent, setAgent] = useState<connect.Agent>();
  const [primaryQueueArn, setPrimaryQueueArn] = useState<string>();

  useEffect(() => {
    function updatePrimaryQueueArn(agent: connect.Agent) {
      const agentQueueArns = agent
        .getAllQueueARNs()
        .filter((queueARN: string) => !queueARN.includes('agent/'));

      const queue = agentQueueArns[0];

      console.debug('AUI:primaryQueueArn', queue);

      setPrimaryQueueArn(queue);
    }


    let agentSubscription = connect.agent((agent: connect.Agent) => {
      setAgent(agent);
      updatePrimaryQueueArn(agent);

      agent.onRefresh((agent: connect.Agent) => {
        setAgent(agent);
        updatePrimaryQueueArn(agent);
      });
    });

    return () => {
      // @ts-ignore
      agentSubscription.unsubscribe();
      setAgent(undefined);
    }
  }, [connect.agent]);

  const object = {
    agent: agent,
    primaryQueueArn: primaryQueueArn
  };

  return object;
}

export default useAgent;